import React from 'react';
import { Route, Routes } from 'react-router-dom';

import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';

import Layout from './components/Layout';

import OrderList from './pages/OrderList'
import OrderCreate from './pages/OrderCreate'
import OrderEdit from './pages/OrderEdit'
import PageNotFound from './pages/404';

import Login from './pages/Login';

function App() {
  return (
    <Routes>      
      {/* public routes */}
      <Route path="login" element={ <Login />} />

      {/* protected routes */}
      <Route element={<PersistLogin />} >
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Layout />} >
            <Route index path="/" element={<OrderList />} />
            <Route path="/jobcards" element={<OrderList />} />
            <Route path="/jobcard/new" element={<OrderCreate />} />
            <Route path="/jobcard/:id" element={<OrderEdit />} />
          </Route>
        </Route>
      </Route>

      {/* 404 page */}
      <Route path="*" element={<PageNotFound />} />

    </Routes>
  );

}

export default App;
