import { useRef } from "react";

import POBased from "./POBased";
import CashBased from "./CashBased";
import Expense from "./Expense";

import useAuth from "../hooks/useAuth";
import useOrder from "../hooks/useOrder";

import { ExternalLink } from 'lucide-react';

const PurchaseExpense = ({data, setData, setNotification, setMessage}) => {

    const {auth} = useAuth();

    const patchOrder = useOrder();

    let pre_production_stage1 = useRef(null);
    let pre_production_stage2 = useRef(null);
    let pre_production_stage3 = useRef(null);

    const isEditable = () => {
        if(auth.groups.includes('viewer') || data.production_approval_status === 'approved' || data.budget_approval_status !== 'approved')
            return false;        
        return true;
    }

    const updateInfo = async () => {

        setMessage("Updating...");
        setNotification(true);

        let formData = new FormData();
        
        if(pre_production_stage1.current && pre_production_stage1.current.files[0]){
            const file = pre_production_stage1.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "pre_production_stage1" + "." + file_format;
            formData.append("pre_production_stage1", file, file_name);
        }
        
        if(pre_production_stage2.current && pre_production_stage2.current.files[0]){
            const file = pre_production_stage2.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "pre_production_stage2" + "." + file_format;
            formData.append("pre_production_stage2", file, file_name);
        }

        if(pre_production_stage3.current && pre_production_stage3.current.files[0]){
            const file = pre_production_stage3.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "pre_production_stage3" + "." + file_format;
            formData.append("pre_production_stage3", file, file_name);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        const response = await patchOrder(data.id, formData, config);
        if(response.status === 200){
            setMessage("Saved!");
            setNotification(true);
            setData(response.data);
        } else {
            setMessage("Failed to update, please check the console for more details");
            setNotification(true);
            console.log(response)
        }

    }


    return (
        <div className={`${ !isEditable() ? 'text-gray-500' : ''}`}>

            <div >
                <h3>Pre Bulk Producton photos</h3> 

                <div className="flex m-2 justify-between">
                    <span>stage 1</span>
                    <div className="flex">
                        <input
                            type="file"
                            name="pre_production_stage1"
                            disabled={!isEditable()}
                            ref={pre_production_stage1}
                        />
                        {data.pre_production_stage1 &&
                            <div className="mx-2 flex">
                                <a href={data.pre_production_stage1} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex m-2 justify-between">
                    <span>stage 2</span>
                    <div className="flex">
                        <input
                            type="file"
                            name="pre_production_stage2"
                            disabled={!isEditable()}
                            ref={pre_production_stage2}
                        />
                        {data.pre_production_stage2 &&
                            <div className="mx-2 flex">
                                <a href={data.pre_production_stage2} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex m-2 justify-between">
                    <span>stage 3</span>
                    <div className="flex">
                        <input
                            type="file"
                            name="pre_production_stage3"
                            disabled={!isEditable()}
                            ref={pre_production_stage3}
                        />
                        {data.pre_production_stage3 &&
                            <div className="mx-2 flex">
                                <a href={data.pre_production_stage3} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                            </div>
                        }
                    </div>
                </div>

            </div>
            { isEditable() && 
                <div className="my-5">
                    <button 
                        className="border w-full py-1 transition duration-200 bg-black hover:bg-blue-600 text-white rounded text-sm capitalize"
                        onClick={updateInfo}>
                            save
                    </button>
                </div>
            }
            <hr/>

            <POBased 
                order_id={data.id} 
                isEditable={isEditable()} 
                setNotification={setNotification} 
                setMessage={setMessage}/>

            <CashBased 
                order_id={data.id} 
                isEditable={isEditable()} 
                setNotification={setNotification} 
                setMessage={setMessage}/>

            <Expense 
                order_id={data.id} 
                isEditable={isEditable()} 
                setNotification={setNotification} 
                setMessage={setMessage}/>
            
        </div>
    )
}

export default PurchaseExpense;