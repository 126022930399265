import { useNavigate } from 'react-router-dom';
import {ChevronLeft} from 'lucide-react';

const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="g-gray-100 flex items-center justify-center min-h-screen">
            <div className="text-center p-4">
                <h1 className="text-6xl font-bold text-gray-800">404</h1>
                <p className="text-xl mt-2 text-gray-600">Whatever you are trying to reach is not available.</p>
                {/* <a href="/" className="mt-6 inline-block text-white bg-blue-500 hover:bg-blue-600 px-5 py-3 rounded">Go Home</a> */}
                <br/>
                
                    
                <button 
                    type="submit" 
                    className="transition duration-200 bg-black hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white rounded-full py-2"
                    onClick={(e) => navigate('/jobcards')}>
                        <div className="flex justify-between px-2">
                            <span>
                                <ChevronLeft />
                            </span>
                        </div>
                </button>
            </div>
        </div>
    )
}

export default PageNotFound;