import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { MaterialReactTable,  useMaterialReactTable, getMRT_RowSelectionHandler} from 'material-react-table'

import { ChevronDown, ArrowDownToLine, CirclePlus} from "lucide-react";
import { useNavigate } from 'react-router-dom';


import * as XLSX from 'xlsx';
import useAuth from '../hooks/useAuth';
import DataContext from '../context/DataProvider';
import { useContext } from 'react';

const OrderList = () => {

    const {auth} = useAuth();

    const navigate = useNavigate();
    const {data, users} = useContext(DataContext)

    // while data is loading
    const [loading, setLoading] = useState(false);

    // for list updation (user and date)
    // TODO:
    // - update the date format to more human friendly
    // - Example: 12 Jun, 4:15pm
    const [ user_updated_data, setUserUpdatedData ] = useState([])
    const [ table_data, setTableData] = useState(data?.results)

    // for pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [totalRows, setTotalRows] = useState(0);

    const getProjectStatus = (row) => {
        
        if(row.project_approval_status === 'open') {
            return 'Open'
        }
        if(row.project_approval_status === 'requested') {
            return 'Project Requested';
        }
        if(row.project_approval_status === 'approved' && row.budget_approval_status === 'open') {
            return 'Project Approved';
        }
        if(row.budget_approval_status === 'requested') {
            return 'Budget Approval Requested';
        }
        if(row.budget_approval_status === 'approved' && row.production_approval_status === 'open') {
            return 'Budget Approved';
        }
        if(row.production_approval_status == 'requested') {
            return 'Production Approval Requested';
        }
        if(row.production_approval_status === 'approved' && row.clouser_approval_status === 'open') {
            return 'Production Approved';
        }
        if(row.clouser_approval_status === 'requsetd') {
            return 'Clouser Requested'
        }
        if(row.clouser_approval_status === 'approved') {
            return 'Closed';
        }

    }

    const columns = [
        {
            header: 'JOBCARD',
            accessorKey: 'jobcard_id'
        },
        {
            header: 'Name',
            accessorKey: 'jobcard_name'
        },
        {
            header: 'Status',
            accessorFn: (row) => getProjectStatus(row),
        },
        {
            header: 'Sales Executive',
            accessorFn: (row) => getUsername(row.primary_executive),
        },
        {
            header: 'Supporting',
            accessorFn: (row) => getUsername(row.secondary_executive),
        },
        {
            header: 'Start Date',
            accessorFn: (row) => toDateString(row.start_date),
        },
        {
            header: "Last Updated",
            accessorFn: (row) => toDateString(row.updated_at),
        }

    ]

    useEffect(()=>{
        setTableData(data.results);
    }, [data])

    const toDateString = (date) => {
        const date_str = date.split('T')[0];
        return date_str
    }

    const getUsername = (user_id) => {

        if(typeof user_id === "string")
            return user_id;

        if(!users || users.length === 0)
            return user_id;

        if(Array.isArray(user_id)){
            let result = [];
            user_id.forEach((id) => result.push(getUsername(id)))
            return result.join(", ");
        }
    
        const user = users?.find(item => item.id === user_id);
        if(!user){
            console.log('no user found')
            return user_id;
        }
        return user.username
    }

    useEffect(()=>{

        // update data with username
        const updated_list = []

        if(!data.results) return;

        data?.results.forEach((item) => {
            updated_list.push(item)
            updated_list[updated_list.length-1].primary_executive = getUsername(updated_list[updated_list.length-1].primary_executive)
            updated_list[updated_list.length-1].secondary_executive = getUsername(updated_list[updated_list.length-1].secondary_executive)
            
            updated_list[updated_list.length-1].updated_at = (new Date(updated_list[updated_list.length-1].updated_at)).toLocaleString();
            updated_list[updated_list.length-1].start_date = (new Date(updated_list[updated_list.length-1].start_date)).toLocaleString();

        });
        setUserUpdatedData(updated_list)
    },[data])

    const table = useMaterialReactTable({
        columns,
        data,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: (event) => {
                navigate(`/jobcard/${row.original.jobcard_id}`);
            },
            sx: {
              cursor: 'pointer',
            },
        })
    })



    return (
        <div>
            
            {/* <div className='flex flex-col px-5 border-b z-10'> */}

                <MaterialReactTable
                    table={table}
                />
                {/* navigate(`/jobcard/${row.id}`) */}


                    {/* <DataTable
                        className='flex-grow'
                        styles={{
                            zIndex: 0
                        }}
                        columns={columns}
                        data={user_updated_data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        highlightOnHover
                        paginationComponentOptions={paginationComponentOptions}
                        sortIcon={<ChevronDown size={10} />}
                        onChangeRowsPerPage={data => setPerPage(data.count)}
                        defaultSortField="last_updated"
                        defaultSortAsc={false}
                        fixedHeader={true}
                        onRowClicked={(row) => navigate(`/jobcard/${row.id}`)}
                    />
                 */}
                
            {/* </div> */}
        </div>
        
    )
}

export default OrderList;