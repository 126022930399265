import Modal from 'react-modal';

Modal.setAppElement('#root');

const PopUp = ({isOpen, onRequestClose, children}) => {
    return (
      <div className='mx-auto'>
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel='Add Details'
            className="max-h-full overflow-y-scroll bg-white w-1/2 mx-auto py-10 px-10 border"
            style={{
                content: {
                  // top: '50%',
                  // left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  top: 'auto',
                  bottom: 'auto',
                  // marginRight: '-50%',
                  // transform: 'translate(0, 20%)'
                }
              }}
            >
            {children}
        </Modal>
      </div>
    );
}

export default PopUp;