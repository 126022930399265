import { useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const CreatePOBased = ({order_id, setNotification, setMessage, closeModal}) => {

    const axios = useAxiosPrivate();

    let vendor = useRef('');
    let primary_poc = useRef('');
    let primary_contact_no = useRef('');
    let primary_email = useRef('');
    let secondary_poc = useRef('');
    let secondary_contact_no = useRef('');
    let secondary_email = useRef('');
    let product_purchased = useRef('');
    let estimate_no = useRef('');
    let estimate_amount = useRef('');
    let other_charges = useRef('');
    let tax = useRef('');
    let total_amount = useRef('');
    let vendor_po_number = useRef('');
    let agreed_delivery_date = useRef('');
    let invoice_date = useRef('');
    let invoice_no = useRef('');
    let total_invoice_amount = useRef('');
    let grn_no = useRef('');
    let grn_date = useRef('');
    let date_of_payment_as_per_terms = useRef('');
    let invoice = useRef('');

    const createPO = async () => {

        setMessage('Creating...');
        setNotification(true);

        let formData = new FormData();
        formData.append("jobcard_id", order_id)
        formData.append("vendor", vendor.current.value)
        formData.append("primary_poc", primary_poc.current.value)
        formData.append("primary_contact_no", primary_contact_no.current.value)
        formData.append("primary_email", primary_email.current.value)
        formData.append("secondary_poc", secondary_poc.current.value)
        formData.append("secondary_contact_no", secondary_contact_no.current.value)
        formData.append("secondary_email", secondary_email.current.value)
        formData.append("product_purchased", product_purchased.current.value)
        formData.append("estimate_no", estimate_no.current.value)
        formData.append("estimate_amount", estimate_amount.current.value)
        formData.append("other_charges", other_charges.current.value)
        formData.append("tax", tax.current.value)
        formData.append("total_amount", total_amount.current.value)
        formData.append("vendor_po_number", vendor_po_number.current.value)
        formData.append("agreed_delivery_date", agreed_delivery_date.current.value)
        formData.append("invoice_date", invoice_date.current.value)
        formData.append("invoice_no", invoice_no.current.value)
        formData.append("total_invoice_amount", total_invoice_amount.current.value)
        formData.append("grn_no", grn_no.current.value)
        formData.append("grn_date", grn_date.current.value)
        formData.append("date_of_payment_as_per_terms", date_of_payment_as_per_terms.current.value)

        const file = invoice.current.files[0]
        formData.append(invoice.current.name, file)

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            }
        };
        
        try {
            const response = await axios.post('/po/', formData, config);
            if(response.status === 201){
                setMessage('Done');
                setNotification(true);
                closeModal();
            }
        } catch (error) {
            console.log(error);
            setMessage('Failed to create, Please check the console for errors');
            setNotification(true);
        }
    }

    return (
        <div>
            
            <div className="text-right">
                <button onClick={() => closeModal()}>
                    X
                </button>
            </div>

            <h3 className="text-center">
                Add new PO Order
            </h3>
            <div className="overflow-y-scroll flex my-2">
                <label className="w-1/2">Vendor/Supplier</label>
                <input 
                    type="text"
                    className="border w-full px-2"
                    ref={vendor}
                    />
            </div>

            <div className="border p-2 m-1 rounded-sm ">

                <div className="flex my-1">
                    <label className="w-1/2">Primary Contact Person Name</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={primary_poc}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Contact No</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={primary_contact_no}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Email</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={primary_email}
                        />
                </div>
            </div>

            <div className="border p-2 m-1 rounded-sm">

                <div className="flex my-1">
                    <label className="w-1/2">Secondary Contact Person Name</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={secondary_poc}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Contact No</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={secondary_contact_no}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Email</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={secondary_email}
                        />
                </div>
            </div>

            <div className="m-2">

                <div className="flex my-1">
                    <label className="w-1/2">Product/Service Purchased</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={product_purchased}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Estimate No</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={estimate_no}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Estimated Amount</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={estimate_amount}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Other Charges</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={other_charges}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Tax</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={tax}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Total Amount</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={total_amount}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Vendor PO No</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={vendor_po_number}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Agreed Delivery Date</label>
                    <input 
                        type="date"
                        className="border w-full px-2"
                        ref={agreed_delivery_date}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Invoice Date</label>
                    <input 
                        type="date"
                        className="border w-full px-2"
                        ref={invoice_date}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Invoice No</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={invoice_no}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Total Invoice Amount</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={total_invoice_amount}
                        />
                </div>

                <div className="flex my-2 justify-between">
                    <label>upload Invoice</label>
                    <input 
                        type="file" 
                        name="invoice"
                        ref={invoice}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">GRN No</label>
                    <input 
                        type="text"
                        className="border w-full px-2"
                        ref={grn_no}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">GRN Date</label>
                    <input 
                        type="date"
                        className="border w-full px-2"
                        ref={grn_date}
                        />
                </div>

                <div className="flex my-1">
                    <label className="w-1/2">Date of Payment as per terms</label>
                    <input 
                        type="date"
                        className="border w-full px-2"
                        ref={date_of_payment_as_per_terms}
                        />
                </div>

                
                    <button 
                        className="border w-full py-2 my-2 transition duration-200 bg-black hover:bg-blue-600 text-white rounded text-sm capitalize"
                        onClick={createPO}
                        >
                        Create PO Order
                    </button>
            </div>    

        </div>
    )
}

export default CreatePOBased;