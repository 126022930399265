import {  useRef } from "react"
import useAuth from "../hooks/useAuth";

import { ExternalLink } from 'lucide-react';
import useOrder from "../hooks/useOrder";

const Production = ({data, setData, setNotification, setMessage}) => {

    const {auth} = useAuth();
    const isEditable = () => {
        if(auth.groups.includes('viewer') || data.production_approval_status === 'approved' || data.budget_approval_status !== 'approved')
            return false;        
        return true;
    }

    const patchOrder = useOrder();

    let quality_checked_by = useRef(data.quality_checked_by);
    let quantity_checked_by = useRef(data.quantity_checked_by);
    let delivery_time_slab = useRef(data.delivery_time_slab);
    let dac_wcc_no = useRef(data.dac_wcc_no);
    let invoice_no = useRef(data.invoice_no);
    let total_invoice_value = useRef(data.total_invoice_value);
    let eway_bill_no = useRef(data.eway_bill_no);

    let product_side_a = useRef(data.product_side_a);
    let product_side_b = useRef(data.product_side_b);
    let product_side_c = useRef(data.product_side_c);
    let product_side_d = useRef(data.product_side_d);
    let product_top = useRef(data.product_top);
    let product_bottom = useRef(data.product_bottom);
    let product_inside = useRef(data.product_inside);

    let sales_invoice = useRef(null);

    const updateInfo = async () => {
        setMessage("Updating...");
        setNotification(true);

        let formData = new FormData();

        formData.append("quality_checked_by", quality_checked_by.current.value);
        formData.append("quantity_checked_by", quantity_checked_by.current.value);
        formData.append("delivery_time_slab", delivery_time_slab.current.value);
        formData.append("dac_wcc_no", dac_wcc_no.current.value);
        formData.append("invoice_no", invoice_no.current.value);
        formData.append("total_invoice_value", total_invoice_value.current.value);
        formData.append("eway_bill_no", eway_bill_no.current.value);

        if(product_side_a.current && product_side_a.current.files[0]){
            const file = product_side_a.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "product_side_a" + "." + file_format;
            formData.append("product_side_a", file, file_name);
        }

        if(product_side_b.current && product_side_b.current.files[0]){
            const file = product_side_b.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "product_side_b" + "." + file_format;
            formData.append("product_side_b", file, file_name);
        }

        if(product_side_c.current && product_side_c.current.files[0]){
            const file = product_side_c.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "product_side_c" + "." + file_format;
            formData.append("product_side_c", file, file_name);
        }

        if(product_side_d.current && product_side_d.current.files[0]){
            const file = product_side_d.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "product_side_d" + "." + file_format;
            formData.append("product_side_d", file, file_name);
        }

        if(product_top.current && product_top.current.files[0]){
            const file = product_top.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "product_top" + "." + file_format;
            formData.append("product_top", file, file_name);
        }

        if(product_bottom.current && product_bottom.current.files[0]){
            const file = product_bottom.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "product_bottom" + "." + file_format;
            formData.append("product_bottom", file, file_name);
        }

        if(product_inside.current && product_inside.current.files[0]){
            const file = product_inside.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "product_inside" + "." + file_format;
            formData.append("product_inside", file, file_name);
        }

        if(sales_invoice && sales_invoice.current.files[0]){
            const file = sales_invoice.current.files[0];
            const file_format = file?.name.split(".").pop();
            const file_name = "sales_invoice" + '.' + file_format;
            formData.append("sales_invoice", file, file_name);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        const response = await patchOrder(data.id, formData, config);
        console.log(response.status)
        if(response.status === 200){
            console.log(response.data);
            setMessage("Saved!");
            setNotification(true);
            setData(response.data);
        } else {
            setMessage("Failed to update, please check the console for more details");
            setNotification(true);
            console.log(response)
        }
    }    

    return (
        <div className={`${ !isEditable() ? 'text-gray-500' : ''}`}>

            <h3>Upload final product images</h3>
            <div>
                <div>

                    <div className="flex m-2 justify-between">
                        <span>side A</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="product_side_a"
                                disabled={!isEditable()}
                                ref={product_side_a}
                            />
                            { data.product_side_a &&
                                <div className="mx-2 flex">
                                    <a href={data.product_side_a} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex m-2 justify-between">
                        <span>side B</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="product_side_b"
                                disabled={!isEditable()}
                                ref={product_side_b}
                            />
                            { data.product_side_b &&
                                <div className="mx-2 flex">
                                    <a href={data.product_side_b} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex m-2 justify-between">
                        <span>side C</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="product_side_c"
                                disabled={!isEditable()}
                                ref={product_side_c}
                            />
                            { data.product_side_c &&
                                <div className="mx-2 flex">
                                    <a href={data.product_side_c} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex m-2 justify-between">
                        <span>side D</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="product_side_d"
                                disabled={!isEditable()}
                                ref={product_side_d}
                            />
                            { data.product_side_d &&
                                <div className="mx-2 flex">
                                    <a href={data.product_side_d} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                    
                    <hr/>
                    <div className="flex m-2 justify-between">
                        <span>top</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="product_top"
                                disabled={!isEditable()}
                                ref={product_top}
                            />
                            { data.product_top &&
                                <div className="mx-2 flex">
                                    <a href={data.product_top} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex m-2 justify-between">
                        <span>bottom</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="product_bottom"
                                disabled={!isEditable()}
                                ref={product_bottom}
                            />
                            { data.product_bottom &&
                                <div className="mx-2 flex">
                                    <a href={data.product_bottom} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex m-2 justify-between">
                    <span>inside</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="product_inside"
                                disabled={!isEditable()}
                                ref={product_inside}
                            />
                            { data.product_inside &&
                                <div className="mx-2 flex">
                                    <a href={data.product_inside} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="flex m-2">
                <label className="w-1/3">Quality Checked by</label>
                <input 
                    type="text"
                    className="border w-full px-2"
                    ref={quality_checked_by}
                    defaultValue={data.quality_checked_by}
                />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Quantity Checked by</label>
                <input 
                    type="text"
                    className="border w-full px-2"
                    ref={quantity_checked_by}
                    defaultValue={data.quantity_checked_by}
                />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Delivery time slab given to customer: (timeframe)</label>
                <input 
                    type="text"
                    className="border w-full px-2"
                    ref={delivery_time_slab}
                    defaultValue={data.delivery_time_slab}
                />
                {/* (*Trigger a email to customer and concern team on delivery Time) */}
            </div>

            <div className="flex m-2">
                <label className="w-1/3">DAC/WCC Nos</label>
                <input
                    type="text"
                    className="border w-full px-2"
                    ref={dac_wcc_no}
                    defaultValue={data.dac_wcc_no}
                />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Invoice No</label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    ref={invoice_no}
                    defaultValue={data.invoice_no}
                />
            </div>

            <div className="flex m-2 justify-between">
                <span>Upload Sales Invoice</span>
                <div className="flex">
                    <input
                        type="file"
                        name="sales_invoice"
                        disabled={!isEditable()}
                        ref={sales_invoice}
                        />
                    { data.sales_invoice &&
                        <div className="mx-2 flex">
                            <a href={data.sales_invoice} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                        </div>
                    }
                </div>
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Total Invoice Value</label>
                <input
                    type="text"
                    className="border w-full px-2"
                    ref={total_invoice_value}
                    defaultValue={data.total_invoice_value}/>
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Eway Bill No</label>
                <input
                    type="text"
                    className="border w-full px-2"
                    ref={eway_bill_no}
                    defaultValue={data.eway_bill_no}
                />
            </div>

            { isEditable() && 
                <div className="my-5">
                    <button 
                        className="border w-full py-1 transition duration-200 bg-black hover:bg-blue-600 text-white rounded text-sm capitalize"
                        onClick={updateInfo}>
                            save
                    </button>
                </div>
            }

        </div>
    )
}
export default Production;