import useAxiosPrivate from './useAxiosPrivate';

const ORDER_URL = "/orders/"

const useOrder = () => {
    const axios = useAxiosPrivate();
    const patchOrder = async (id, patchData, config) => {
        try {
            const response = await axios.patch(ORDER_URL + id + '/', patchData, config);
            return response; 

        } catch (error) {
            console.log("Error in request:", error);
            return { 
                error: true,
                status: error.response?.status,
                message: error.message
            };
        }
    }
    return patchOrder;
}

export default useOrder;
