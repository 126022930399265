import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { createContext, useState, useEffect } from 'react';

const DataContext = createContext({data:[], refreshData: ()=>{}})

export const DataProvider = ({children}) => {

    const {isAuthenticated} = useAuth();

    const [isLoading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [users, setUsers] = useState([])
    const axios = useAxiosPrivate()

    const fetchData = async () => {
        try{
            const response = await axios.get('orders/')
            setData(response.data.results)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchUsers = async () => {
        try {
            const response = await axios.get('users/')
            setUsers(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if(isAuthenticated) {
            setLoading(true);
            fetchData();
            fetchUsers();
            setLoading(false);
        }
        
    },[isAuthenticated])

    const refreshData = () => {
        setLoading(true);
        fetchData();
        fetchUsers();
        setLoading(false);   
    }

    const getUsername = (user_id) => {

        if(typeof user_id === "string")
            return user_id;

        if(!users || users.length == 0)
            return user_id;

        if(Array.isArray(user_id)){
            let result = [];
            user_id.forEach((id) => result.push(getUsername(id)))
            return result.join(", ");
        }
        
        const user = users?.find(item => item.id === user_id);

        if(!user){
            return user_id;
        }
        
        return user.username
    }

    return (
        <DataContext.Provider value={{data, users, isLoading, refreshData, getUsername}}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;