import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import DataContext from '../context/DataProvider';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from "../hooks/useAuth";

const CREATE_URL = "/orders/"

const CreateOrder = () => {

    
    const {refreshData} = useContext(DataContext);
    const {auth} = useAuth();

    const axios = useAxiosPrivate();
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [eta, setETA] = useState();

    let start_date = useRef();
    let jobcard_name_ref = useRef();

    const [primary_executive, setPrimary] = useState(0);
    const [secondary_executive, setSecondary] = useState(0);
    const [other_executives, setOthers] = useState([]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/users/',{
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });
            setUsers(response?.data);
            console.log("users with id", response.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {

            const payload = {
                "jobcard_name": jobcard_name_ref.current.value,
                "eta": eta,
                "start_date": start_date.current.value,
                "primary_executive": (auth.groups.includes('creator') ? auth.user_id: primary_executive),
                "secondary_executive": secondary_executive,
                "other_executives": other_executives
            }


            const response = await axios.post(CREATE_URL,
                payload,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );

            if(response.status === 201) {
                console.log(response.data.id); // get the jobcard id and use it to navigate
                refreshData();
                navigate(`/jobcard/${response.data.jobcard_id}`);
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    const formatResult = (item) => {
        return (
          <div >
            <span className='text-black'>{item.username}</span>
          </div>
        )
    }

    const getUsername = (user_id) => {

        if(typeof user_id === "string")
            return user_id;

        if(!users || users.length == 0)
            return user_id;

        if(Array.isArray(user_id)){
            let result = [];
            user_id.forEach((id) => result.push(getUsername(id)))
            return result.join(", ");
        }
        
        const user = users?.find(item => item.id === user_id);

        if(!user){
            console.log('no user found')
            return user_id;
        }
        
        return user.username
    }

    const renderStartringDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (`0${today.getMonth() + 1}`).slice(-2); // getMonth is zero-based
        const day = (`0${today.getDate()}`).slice(-2);
        const currentDate = `${year}-${month}-${day}`;

        return (
            <div className='flex m-2'>
                <label className='w-1/3'>
                    start date
                </label>
                <input
                    type="date"
                    className='border w-full px-2'
                    ref={start_date}
                    value={currentDate}
                    disabled={true}
                />
                
            </div>
        )
    }


    const renderPrimary = () => {

        if (auth.groups.includes('creator')) {
            return  (
                <div className='py-3 px-5'>
                    <label for="prmary_owner" className='w-1/3'>Sales Executive</label> 
                    <input type='text' id="primary_owner" defaultValue={auth.name} className='bg-gray-200 px-5 py-1 w-full my-2' disabled/>
                </div>
            )
        }
        else if (auth.groups.includes('admin')) {
            return (
                <div className='py-2 z-10 px-5 w-1/3'>
                    <label for="prmary_owner" className='w-1/3'>Sales Executive</label> 
                    <ReactSearchAutocomplete
                        showIcon={false}
                        className='w-full z-10 border my-3'
                        fuseOptions={{keys: ["username"]}}
                        resultStringKeyName="username"
                        items={users}
                        formatResult={formatResult}
                        styling={{
                            borderRadius: "0px",
                            height: "30px",
                            padding: "2px",
                            border: "none",
                            zIndex: 10,
                        }}
                        onSelect={(user) => setPrimary(user.id)}
                    />
                
                </div>
            )
        }
    }

    const renderSecondary = () => {
        return (
            <div className='py-2 z-10 px-5 w-1/3'>
                <label for="secondary_owner">Supporting Executive</label> 
                <span >
                    <ReactSearchAutocomplete
                        id="secondary_owner"
                        showIcon={false}
                        className='w-full z-10 border my-3'
                        fuseOptions={{keys: ["username"]}}
                        resultStringKeyName="username"
                        items={users}
                        formatResult={formatResult}
                        styling={{
                            borderRadius: "0px",
                            height: "30px",
                            padding: "2px",
                            border: "none",
                            zIndex: 10,
                        }}
                        onSelect={(user) => setSecondary(user.id)}
                    />
                </span>
            </div>
        )
    }
    const renderOthers = () => {

        return (
            <div className='py-2 z-10 px-5 w-1/3'>
                <label for="others" className='w-1/3'>Others</label> 
                <span className='w-full'>
                    <ReactSearchAutocomplete
                        showIcon={false}
                        className='w-full z-10 border my-3'
                        fuseOptions={{keys: ["username"]}}
                        resultStringKeyName="username"
                        items={users}
                        formatResult={formatResult}
                        styling={{
                            borderRadius: "0px",
                            height: "30px",
                            padding: "2px",
                            border: "none",
                            zIndex: 10,
                        }}
                        onSelect={(user) => setOthers([user.id])}
                    />
                </span>
            </div>
        )
    }

    return (
        <div className='pt-2 pb-10 px-5'>

            <h1>Create new order</h1>
            <br/>

            <form onSubmit={handleSubmit}>

                <div>

                    <div className='flex m-2'>
                        <label for="order_name" className='w-1/3'>Jobcard Name</label>
                        <span className='w-full'>
                            <input 
                                type="text" 
                                className='border w-full px-2'
                                ref={jobcard_name_ref}
                                />
                        </span>
                    </div>
                    

                    {renderStartringDate()}

                    <div className='flex m-2'>
                        <label for="order_name" className='w-1/3'>projected closing Date</label>
                        <span className='w-full'>
                            <input 
                                type="date" 
                                id="eta"
                                className='border w-full px-2'
                                onChange={(e) => setETA(e.target.value)}/>
                        </span>
                    </div>

                    <div className='flex'>
                        {renderPrimary()}
                        {renderSecondary()}
                        {renderOthers()}
                    </div>

                    <div className='py-2'>
                        <button type="submit" className="transition duration-200 bg-black hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white rounded-lg py-2">
                            <div className="flex justify-between px-5 ">
                                <span className="inline-block mr-2">Create</span>
                                
                            </div>
                        </button>
                    </div>    

                </div>
                
            </form>
        </div>
    )
}

export default CreateOrder;