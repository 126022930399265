import { useContext } from "react"
import DataContext from "../context/DataProvider"
import useOrder from "../hooks/useOrder";
import useAuth from "../hooks/useAuth";

const JobCardInfoPanel = ({data, setData, setNotification, setMessage}) => {

    const {auth} = useAuth();
    const {getUsername} = useContext(DataContext);
    const patchOrder = useOrder();

    const projectApprovalTimeline = () => {

        const requestForProjectApproval = async () => {

            const date = new Date();
    
            const payload = {
                "project_approval_status": "requested",
                "project_approval_requested_by": auth.user_id,
                "project_approval_requested_date": date.toISOString()
            }
    
            const response = await patchOrder(data.id, payload);
            if(response.status === 200){
                setData(response.data);
                setMessage('Requested');
                setNotification(true);
            }
        }
    
        const approveProject = async () => {
            const date = new Date();
            const payload = {
                "project_approval_status": "approved",
                "project_approval_approved_by": auth.user_id,
                "project_approval_approved_date": date.toISOString()
            }
            const response = await patchOrder(data.id, payload);
            if(response.status === 200){
                setData(response.data);
                setMessage('Approved');
                setNotification(true);
            }
        }
    
        return (
            <div className="py-2">

                {/* project approval is still open */}
                {data.project_approval_status === 'open' && 
                    <button 
                        className="border w-full py-2 capitalize transition duration-200 bg-black hover:bg-blue-600 text-white rounded mt-2"
                        onClick={requestForProjectApproval}
                        >
                        request for project approval
                    </button>
                }

                {/* project approval history */}
                {(data.project_approval_status === 'requested' || data.project_approval_status === 'approved') && 

                    <div className="border py-2 rounded px-3">

                        <h3 className="border-b pb-2">Project</h3>

                        <div className="px-2 pt-2 flex justify-between capitalize">
                            <label>requested on</label>
                            <span>{getDateFormat(data.project_approval_requested_date)}</span>
                        </div>
                        <div className="px-2 pt-2 flex justify-between capitalize">
                            <label>requested by</label>
                            <span>{getUsername(data.project_approval_requested_by)}</span>
                        </div>
                    
                        {data.project_approval_status === 'approved' && 
                            <div>
                                <div className="px-2 pt-2 flex justify-between capitalize border-t mt-2">
                                    <label>approved On</label>
                                    <span>{getDateFormat(getUsername(data.project_approval_approved_date))}</span>
                                </div>
                                <div className="px-2 pt-2 flex justify-between capitalize">
                                    <label>approved by</label>
                                    <span>{getUsername(data.project_approval_approved_by)}</span>
                                </div>   
                            </div>
                        }

                    </div>
                }

                {auth.groups.includes('creator') && data.project_approval_status === 'requested' && 
                        <div className="my-4 text-center rounded  border py-2">
                            Project Approval is Pending
                        </div>
                }

                {(auth.groups.includes('admin') && data.project_approval_status === 'requested')&& 
                    <button 
                        className="border w-full py-2 capitalize transition duration-200 bg-black hover:bg-blue-600 text-white rounded mt-2"
                        onClick={approveProject}>
                        Approve Project
                    </button>
                }


            </div>
        )
    }

    const budgeApprovalTimeline = () => {

        // if still in project approval state, then there is no need
        if(data.project_approval_status !== 'approved')
            return;

        const requestForBudgetApprove = async () => {
            const date = new Date();
            const payload = {
                "budget_approval_status": "requested",
                "budget_approval_requested_date": date.toISOString(),
                "budget_approval_requested_by": auth.user_id
            }
            const response = await patchOrder(data.id, payload);
            if(response.status === 200){
                setData(response.data);
                setMessage('Requested');
                setNotification(true);
            }
        }

        const approveBudget = async () => {
            const date = new Date();

            const payload = {
                "budget_approval_status": "approved",
                "budget_approval_approved_date": date.toISOString(),
                "budget_approval_approved_by": auth.user_id
            }
            const response = await patchOrder(data.id, payload);
            if(response.status === 200){
                setData(response.data);
                setMessage('Approved');
                setNotification(true);
            }
        }

        return (
            <div className="py-2">
                {data.budget_approval_status === 'open' && 
                    <div>
                        <button 
                            className="border w-full py-2 capitalize transition duration-200 bg-black hover:bg-blue-600 text-white rounded mt-2"
                            onClick={requestForBudgetApprove}>
                            request For Budget Approval
                        </button>
                    </div>
                }

                {/* project approval history */}
                {(data.budget_approval_status === 'requested' || data.budget_approval_status === 'approved') && 

                    <div className="border py-2 rounded  px-3">

                        <h3 className="border-b pb-2">Budget</h3>

                        <div className="px-2 pt-2 flex justify-between capitalize">
                            <label>requested on</label>
                            <span>{getDateFormat(data.budget_approval_requested_date)}</span>
                        </div>
                        <div className="px-2 pt-2 flex justify-between capitalize">
                            <label>requested by</label>
                            <span>{getUsername(data.budget_approval_requested_by)}</span>
                        </div>

                        {data.budget_approval_status === 'approved' && 
                            <div>
                                <div className="px-2 pt-2 flex justify-between capitalize border-t mt-2">
                                    <label>approved On</label>
                                    <span>{getDateFormat(getUsername(data.budget_approval_approved_date))}</span>
                                </div>
                                <div className="px-2 pt-2 flex justify-between capitalize">
                                    <label>approved by</label>
                                    <span>{getUsername(data.budget_approval_approved_by)}</span>
                                </div>   
                            </div>
                        }

                    </div>
                    }

                    {auth.groups.includes('creator') && data.budget_approval_status === 'requested' && 
                        <div className="my-4 text-center rounded  border py-2">
                            Budget Approval is Pending
                        </div>
                    }

                    {(auth.groups.includes('admin') && data.budget_approval_status === 'requested')&& 
                    <button 
                        className="border w-full py-2 capitalize transition duration-200 bg-black hover:bg-blue-600 text-white rounded mt-2"
                        onClick={approveBudget}>
                        Approve Budget
                    </button>
                    }
                
            </div>
        )
    }

    const productionApprovalTimeline = () => {

        // if still in project approval state, then there is no need
        if( data.budget_approval_status !== 'approved')
            return;

        const requestForProductionApproval = async () => {

            const date = new Date();

            const payload = {
                "production_approval_status": "requested",
                "production_approval_requested_by": auth.user_id,
                "production_approval_requested_date": date.toISOString()
            }
            const response = await patchOrder(data.id, payload)
            if(response.status === 200){
                setData(response.data);
                setMessage('Requested');
                setNotification(true);
            }
        }

        const approveProduction = async () => {
            const date = new Date();
            const payload = {
                "production_approval_status": "approved",
                "production_approval_approved_date": date.toISOString(),
                "production_approval_approved_by": auth.user_id
            }
            const response = await patchOrder(data.id, payload);
            if(response.status === 200){
                setData(response.data);
                setMessage('Approved');
                setNotification(true);
            }
        }

        return (
            <div>
                <div className="py-2">
                    {data.production_approval_status === 'open' && 
                        <div>
                            <button 
                                className="border w-full py-2 capitalize transition duration-200 bg-black hover:bg-blue-600 text-white rounded mt-2"
                                onClick={requestForProductionApproval}
                                >
                                request For Production Approval
                            </button>
                        </div>
                    }

                    {/* project approval history */}
                    {(data.production_approval_status === 'requested' || data.production_approval_status === 'approved') && 

                        <div className="border py-2 rounded  px-3">

                            <h3 className="border-b pb-2">Production</h3>

                            <div className="px-2 pt-2 flex justify-between capitalize">
                                <label>requested on</label>
                                <span>{getDateFormat(data.production_approval_requested_date)}</span>
                            </div>
                            <div className="px-2 pt-2 flex justify-between capitalize">
                                <label>requested by</label>
                                <span>{getUsername(data.production_approval_requested_by)}</span>
                            </div>

                            {data.production_approval_status === 'approved' && 
                                <div>
                                    <div className="px-2 pt-2 flex justify-between capitalize border-t mt-2">
                                        <label>approved On</label>
                                        <span>{getDateFormat(getUsername(data.production_approval_approved_date))}</span>
                                    </div>
                                    <div className="px-2 pt-2 flex justify-between capitalize">
                                        <label>approved by</label>
                                        <span>{getUsername(data.production_approval_approved_by)}</span>
                                    </div>   
                                </div>
                            }

                        </div>
                    }

                    {auth.groups.includes('creator') && data.production_approval_status === 'requested' && 
                        <div className="my-4 text-center rounded  border py-2">
                            Production Approval is Pending
                        </div>
                    }

                    {(auth.groups.includes('admin') && data.production_approval_status === 'requested')&& 
                    <button 
                        className="border w-full py-2 capitalize transition duration-200 bg-black hover:bg-blue-600 text-white rounded mt-2"
                        onClick={approveProduction}>
                        Approve Production
                    </button>
                    }
            </div>
        </div>
        )   
    }

    const clouserApprovalTimeline = () => {

        // if still in project approval state, then there is no need
        if(data.production_approval_status !== 'approved')
            return;

        const requestForClouserApprove = async () => {
            const date = new Date();
            const payload = {
                "clouser_approval_status": "requested",
                "clouser_approval_requested_date": date.toISOString(),
                "clouser_approval_requested_by": auth.user_id
            }
            const response = await patchOrder(data.id, payload);
            if(response.status === 200){
                setData(response.data);
                setMessage('Requested');
                setNotification(true);
            }
        }

        const approveClouser = async () => {
            const date = new Date();

            const payload = {
                "clouser_approval_status": "approved",
                "clouser_approval_approved_date": date.toISOString(),
                "clouser_approval_approved_by": auth.user_id
            }
            const response = await patchOrder(data.id, payload);
            if(response.status === 200){
                setData(response.data);
                setMessage('Approved');
                setNotification(true);
            }
        }

        return (
            <div className="py-2">
                {data.clouser_approval_status === 'open' && 
                    <div>
                        <button 
                            className="border w-full py-2 capitalize transition duration-200 bg-black hover:bg-blue-600 text-white rounded mt-2"
                            onClick={requestForClouserApprove}>
                            request For Clouser Approval
                        </button>
                    </div>
                }

                {/* project approval history */}
                {(data.clouser_approval_status === 'requested' || data.clouser_approval_status === 'approved') && 

                    <div className="border py-2 rounded  px-3">

                        <h3 className="border-b pb-2">Clouser</h3>

                        <div className="px-2 pt-2 flex justify-between capitalize">
                            <label>requested on</label>
                            <span>{getDateFormat(data.clouser_approval_requested_date)}</span>
                        </div>
                        <div className="px-2 pt-2 flex justify-between capitalize">
                            <label>requested by</label>
                            <span>{getUsername(data.clouser_approval_requested_by)}</span>
                        </div>

                        {data.project_approval_status === 'approved' && 
                            <div>
                                <div className="px-2 pt-2 flex justify-between capitalize border-t mt-2">
                                    <label>approved On</label>
                                    <span>{getDateFormat(getUsername(data.clouser_approval_approved_date))}</span>
                                </div>
                                <div className="px-2 pt-2 flex justify-between capitalize">
                                    <label>approved by</label>
                                    <span>{getUsername(data.clouser_approval_approved_by)}</span>
                                </div>   
                            </div>
                        }

                    </div>
                    }

                    {(auth.groups.includes('admin') && data.clouser_approval_status === 'requested')&& 
                    <button 
                        className="border w-full py-2 capitalize transition duration-200 bg-black hover:bg-blue-600 text-white rounded mt-2"
                        onClick={approveClouser}>
                        Close Job Card
                    </button>
                    }
                
            </div>
        )
    }


    const getDateFormat = (date_str) => {
        let date = new Date(date_str);
        // jan -> 0
        // feb -> 1 ...
        return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
    }

    return (
        <div className="w-full px-2 text-sm">

            <div>
                <div className="border text-slate-800 mx-2 py-3 font-semibold text-lg rounded-lg px-2 mt-3">
                    {data.jobcard_id}
                </div>
            </div>

            <div className="mt-2">
                <div className="px-4 py-1 flex justify-between">
                    <label>Sales Executive</label>
                    <span>{getUsername(data.primary_executive)}</span>
                </div>
            
                <div className="px-4 py-1 flex justify-between">
                    <label>Supporting Executive</label>
                    <span>{getUsername(data.secondary_executive)}</span>
                </div>
                
                <div className="px-4 py-1 flex justify-between">
                    <label>Others</label>
                    <span>{getUsername(data.other_executives)}</span>
                </div>

                <hr/>

                <div className="px-4 py-2 flex justify-between">
                    <label>JC Opening Date</label>
                    <span>{getDateFormat(data.start_date)}</span>
                </div>

                <div className="px-4 py-2  pb-3 flex justify-between">
                    <label>Projected JC Closing Date</label>
                    <span>{getDateFormat(data.eta)}</span>
                </div>
            </div>

            <hr/>
            
            {projectApprovalTimeline()}
            {budgeApprovalTimeline()}
            {productionApprovalTimeline()}
            {clouserApprovalTimeline()}

        </div>
    )
}

export default JobCardInfoPanel;