import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import * as jose from 'jose';

import { ChevronsRight, EyeOff, Eye } from "lucide-react";
import logo from '../assets/images/logo.svg';

import useAuth from '../hooks/useAuth';
import axios from '../api/axios';

const LOGIN_URL = '/token/';

const Login = () => {

    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const from = "/jobcards/"

    const userRef = useRef();
    const errRef = useRef();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [username, password]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ username, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
    
            const accessToken = response?.data?.access;
            const refreshToken = response?.data?.refresh;
            const {user_id, name, email, groups} = jose.decodeJwt(accessToken)

            localStorage.setItem('token', JSON.stringify({'access': accessToken, 'refresh': refreshToken}));
            setAuth({ user_id, name, email, groups, accessToken });
            setUsername('');
            setPassword('');
            navigate(from, { replace: true });
            
        } catch (err) {
            console.log(err);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Username / Password incorrect');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])


    return (
        <section>
            <div className="min-h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
                <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
                    <img src={logo} alt="instawings"/>
                    <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
                        <form onSubmit={handleSubmit} className="px-5 py-7">
                            <label htmlFor="username" className="font-semibold text-sm text-gray-600 pb-1 block">Username</label>
                            <input 
                                className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
                                type="text"
                                id="username" 
                                ref={userRef}
                                autoComplete='off'
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                required
                            />

                            <label htmlFor="password" className="font-semibold text-sm text-gray-600 pb-1 block">Password</label>
                            <div className="password-container" style={{ position: 'relative' }}>
                                <input
                                    className="border rounded-lg px-3 py-2 mt-1 text-sm w-full"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                />
                                <button
                                    onClick={togglePasswordVisibility}
                                    style={{ position: 'absolute', top: '12px', right: '10px' }}
                                >
                                    {showPassword ? <EyeOff className='text-gray-500' size={20}/> : <Eye className='text-gray-500' size={20}/>}
                                </button>
                        </div>

                            <div className="persistCheck py-2">
                                <input
                                    type="checkbox"
                                    id="persist"
                                    onChange={(e) => setPersist(e.target.checked)}
                                    defaultChecked={false}
                                />
                                <span className='px-2'>
                                    <label htmlFor="persist py-2 px-2">Trust This Device</label>
                                </span>
                            </div>

                            <div className='relative justify-center text-center text-sm m-auto flex'>
                                <p ref={errRef} className={`absolute pb-2 text-red-600 py-2 mt-1 mb-1 rounded-lg ${errMsg? "visible": "invisible"}`}  > {errMsg} </p>
                            </div>

                            <button type="submit" className="transition duration-200 bg-black hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block mt-10">
                                <div className="flex justify-between px-5 ">
                                    <span className="inline-block mr-2">Login</span>
                                    <ChevronsRight />
                                </div>
                            </button>
                        </form>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login