import { useRef } from "react";
import useAuth from "../hooks/useAuth";
import useOrder from "../hooks/useOrder";

import { ExternalLink, ChevronDown, ChevronUp } from 'lucide-react';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useState } from "react";

const Budget = ({data, setData, setNotification, setMessage}) => {

    const {auth} = useAuth();
    const isEditable = () => {
        if(auth.groups.includes('viewer') || data.budget_approval_status === 'approved' || data.project_approval_status !== 'approved')
            return false;        
        return true;
    }

    const patchOrder = useOrder();

    const [totalBudget, setTotalBudget] = useState(data.budget_for_purchace + data.budget_for_transport + data.budget_for_other);
    const [expandSamplePhotos, setExpandSamplePhotos] = useState(false);

    let project_start_date_ref = useRef(data.project_start_date);
    let delivery_date_agreed_ref = useRef(data.delivery_date_agreed);
    let budget_for_purchace_ref = useRef(data.budget_for_purchace);
    let budget_for_transport_ref = useRef(data.budget_for_transport);
    let budget_for_other_ref = useRef(data.budget_for_other);

    let design_reference_image = useRef(data.design_reference_image)
    let design_approval_mail = useRef(data.design_approval_mail)
    let sample_side_a = useRef(data.sample_side_a)
    let sample_side_b = useRef(data.sample_side_b)
    let sample_side_c = useRef(data.sample_side_c)
    let sample_side_d = useRef(data.sample_side_d)
    let sample_top = useRef(data.sample_top)
    let sample_bottom = useRef(data.sample_bottom)
    let sample_inside = useRef(data.sample_inside)

    const updateTotal = () => {
        let total = 0;
        total += Number(budget_for_purchace_ref.current.value);
        total += Number(budget_for_transport_ref.current.value);
        total += Number(budget_for_other_ref.current.value);
        setTotalBudget(total);
    }
    
    const updateInfo = async () => {

        setMessage("Updating...");
        setNotification(true);

        let formData = new FormData();

        formData.append("primary_executive", data.primary_executive);
        formData.append("secondary_executive", data.secondary_executive);
        formData.append("other_executives", data.other_executives);
        formData.append("project_start_date", project_start_date_ref.current.value);
        formData.append("delivery_date_agreed", delivery_date_agreed_ref.current.value);
        formData.append("budget_for_purchace", budget_for_purchace_ref.current.value);
        formData.append("budget_for_transport", budget_for_transport_ref.current.value);
        formData.append("budget_for_other", budget_for_other_ref.current.value);
        formData.append("total_budget", totalBudget);

        if(design_reference_image.current && design_reference_image.current.files[0]){
            const file = design_reference_image.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "design_reference_image" + "." + file_format;
            formData.append('design_reference_image', file, file_name);
        }

        if(design_approval_mail.current && design_approval_mail.current.files[0]) {
            const file = design_approval_mail.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "design_approval_mail" + "." + file_format;
            formData.append('design_approval_mail', file, file_name);
        }

        if(sample_side_a.current && sample_side_a.current.files[0]) {
            const file = sample_side_a.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "sample_side_a" + "." + file_format;
            formData.append('sample_side_a', file, file_name);
        }

        if(sample_side_b.current && sample_side_b.current.files[0]) {
            const file = sample_side_b.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "sample_side_b" + "." + file_format;
            formData.append('sample_side_b', file, file_name);
        }

        if(sample_side_c.current && sample_side_c.current.files[0]) {
            const file = sample_side_c.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "sample_side_c" + "." + file_format;
            formData.append('sample_side_c', file, file_name);
        }

        if(sample_side_d.current && sample_side_d.current.files[0]) {
            const file = sample_side_d.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "sample_side_d" + "." + file_format;
            formData.append('sample_side_d', file, file_name);
        }

        if(sample_top.current && sample_top.current.files[0]) {
            const file = sample_top.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "sample_top" + "." + file_format;
            formData.append('sample_top', file, file_name);
        }

        if(sample_bottom.current && sample_bottom.current.files[0]) {
            const file = sample_bottom.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "sample_bottom" + "." + file_format;
            formData.append('sample_bottom', file, file_name);
        }

        if(sample_inside.current && sample_inside.current.files[0]) {
            const file = sample_inside.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "sample_inside" + "." + file_format;
            formData.append('sample_inside', file, file_name);
        }
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        const response = await patchOrder(data.id, formData, config);
        if(response.status === 200){
            setMessage("Saved!");
            setNotification(true);
            setData(response.data);
        } else {
            setMessage("Failed to update, please check the console for more details");
            setNotification(true);
            console.log(response)
        }
        
    }


    return (
        <div className={`${ !isEditable() ? 'text-gray-500' : ''}`}>
            <div className="flex m-2">
                <label className="w-1/3">Project Start Date</label>
                <input 
                    type="date" 
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={project_start_date_ref}
                    defaultValue={data.project_start_date}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">
                    Delivery Date agreed to customer 
                    {/* [(Trigger a email)] */}
                </label>
                <input 
                    type="date" 
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={delivery_date_agreed_ref}
                    defaultValue={data.delivery_date_agreed}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">
                    Budget for purchase of product / service
                </label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={budget_for_purchace_ref}
                    defaultValue={data.budget_for_purchace}
                    onChange={updateTotal}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">
                    Budget for Transportation
                </label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={budget_for_transport_ref}
                    defaultValue={data.budget_for_transport}
                    onChange={updateTotal}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">
                    Budget for other expenses
                </label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={budget_for_other_ref}
                    defaultValue={data.budget_for_other}
                    onChange={updateTotal}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">
                    Total Budget for this project
                </label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    disabled={true}
                    value={totalBudget}
                    />
            </div>

            <hr/>

            <div>
                <div className="flex m-2 justify-between">
                    <span>Design Reference Image</span>
                    <div className="flex">
                        <input 
                            type="file" 
                            disabled={!isEditable()}
                            name="design_reference_image"
                            ref={design_reference_image}
                        />
                        { data.design_reference_image &&
                            <div className="mx-2 flex">
                                <a href={data.design_reference_image} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                            </div>
                        }
                    </div>
                </div>

                <div className="flex m-2 justify-between">
                    <span>Design Approval Mail</span>
                    <div className="flex">
                        <input 
                            type="file" 
                            name="design_approval_mail"
                            disabled={!isEditable()}
                            ref={design_approval_mail}
                        />
                        { data.design_approval_mail &&
                            <div className="mx-2 flex">
                                <a href={data.design_approval_mail} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                            </div>
                        }
                    </div>
                </div>
            </div>


            <div>
                <div className="flex justify-between bg-gray-100 mr-2 pr-2" onClick={(e) => {setExpandSamplePhotos((prev) => !prev)}}>
                    <h3 className="m-2">Upload Sample photo</h3>
                    <div className="my-auto">
                        {expandSamplePhotos && 
                            <ChevronUp className="my-auto" size={20} />
                        }
                        {!expandSamplePhotos && 
                            <ChevronDown className="my-auto" size={20} />
                        }
                    </div>
                    
                </div>
                <div className={`${expandSamplePhotos? 'visible': 'hidden'}`}>
                    <div className="flex m-2 justify-between">
                        <span>side 1</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="sample_side_a"
                                disabled={!isEditable()}
                                ref={sample_side_a}
                            />
                            { data.sample_side_a &&
                                <div className="mx-2 flex">
                                    <a href={data.sample_side_a} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex m-2 justify-between">
                        <span>side 2</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="sample_side_b"
                                disabled={!isEditable()}
                                ref={sample_side_b}
                            />
                            { data.sample_side_b &&
                                <div className="mx-2 flex">
                                    <a href={data.sample_side_b} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex m-2 justify-between">
                        <span>side 3</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="sample_side_c"
                                disabled={!isEditable()}
                                ref={sample_side_c}
                            />
                            { data.sample_side_c &&
                                <div className="mx-2 flex">
                                    <a href={data.sample_side_c} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex m-2 justify-between">
                        <span>side 4</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="sample_side_d"
                                disabled={!isEditable()}
                                ref={sample_side_d}
                            />
                            { data.sample_side_d &&
                                <div className="mx-2 flex">
                                    <a href={data.sample_side_d} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex m-2 justify-between">
                        <span>top</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="sample_top"
                                disabled={!isEditable()}
                                ref={sample_top}
                            />
                            { data.sample_top &&
                                <div className="mx-2 flex">
                                    <a href={data.sample_top} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex m-2 justify-between">
                        <span>bottom</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="sample_bottom"
                                disabled={!isEditable()}
                                ref={sample_bottom}
                            />
                            { data.sample_bottom &&
                                <div className="mx-2 flex">
                                    <a href={data.sample_bottom} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex m-2 justify-between">
                        <span>inside</span>
                        <div className="flex">
                            <input 
                                type="file" 
                                name="sample_inside"
                                disabled={!isEditable()}
                                ref={sample_inside}
                            />
                            { data.sample_inside &&
                                <div className="mx-2 flex">
                                    <a href={data.sample_inside} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                
            </div>

            { isEditable() && 
                <div className="my-5">
                    <button 
                        className="border w-full py-1 transition duration-200 bg-black hover:bg-blue-600 text-white rounded text-sm capitalize"
                        onClick={updateInfo}>
                            save
                    </button>
                </div>
            }

            
        </div>
    )
}

export default Budget;