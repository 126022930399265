import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import Project from "../components/Project";
import Budget from "../components/Budget";
import PurchaseExpense from '../components/PurchaseExpense';
import Production from "../components/Production";
import Closure from '../components/Closure';
import JobCardInfoPanel from "../components/JobCardInfoPanel";

import { LoaderCircle } from "lucide-react";

const OrderEdit = () => {

    const { id } = useParams();
    const axios = useAxiosPrivate();
    
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [activeTab, setActiveTab] = useState(1);

    const [notification, setNotification] = useState(false);
    const [message, setMessage] = useState(null);

    const navigate = useNavigate();

    // fetch data
    const fetchData = async (id) => {

        const getJobcardIdFromName = () => {
            return id.substring(10, id.length);
        }


        setLoading(true);
        try {
            const response = await axios.get(`orders/${getJobcardIdFromName(id)}`);

            if(response.status === 200){

                setData(response.data);
                
                const project = response.data.project_approval_status;
                const budget = response.data.budget_approval_status;
                const production = response.data.production_approval_status;
                const clouser = response.data.clouser_approval_status;

                if(project !== 'approved'){
                    setActiveTab(1);
                    return;
                }

                if(project === 'approved' && budget !== 'approved'){
                    setActiveTab(2);
                    return;
                }

                if(project === 'approved' && budget === 'approved' && production !== 'approved'){
                    setActiveTab(3);
                    return;
                }

                if(project === 'approved' && budget === 'approved' && production === 'approved'){
                    setActiveTab(5);
                    return;
                }
            }
            else if(response.status === 404) {
                navigate("/404");
            }

        } catch (err) {
            if(err.request.status === 404)
                navigate("/404");
            else{
                console.log(err);
            }
        } finally {
            setLoading(false);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') 
            return;
        setNotification(false);
    }

    useEffect(() => {
        fetchData(id);
    }, [id]);

    const renderLoading = () => {
        return (
            <div className="flex h-screen"><span className="mx-auto my-auto animate-spin"><LoaderCircle /></span></div>
        )
    }

    return (
        <div className="flex">

            <Snackbar
                open={notification}
                autoHideDuration={2000}
                onClose={handleClose}>

                <SnackbarContent message={message} />
            </Snackbar>

            {isLoading &&
                renderLoading()}

            {/* jobcard info */}
            <div className="border-l h-screen sticky top-0 overflow-y-scroll w-1/2 max-w-[400px] border-r" >
                <JobCardInfoPanel 
                    data={data} 
                    setData={setData} 
                    setNotification={setNotification} 
                    setMessage={setMessage}
                />
            </div>

            

            {/* order edit */}
            <div className="w-full mx-3">
                <div className="sticky top-0 rounded-lg  pt-2 mr-3 bg-white my-1">
                    <ul className="flex z-10 px-2 border-b">

                        <li className={`flex pr-4 mr-2 py-2 ${activeTab === 1 ? 'text-blue-700 border-b-2 border-blue-700' : 'text-gray-500 hover:text-gray-800'}`}>
                            <a href="#" className="flex" onClick={() => setActiveTab(1)}>Project</a>
                        </li>
                        
                        <li className={`flex pr-4 mx-2 py-2 ${activeTab === 2 ? 'text-blue-700 border-b-2 border-blue-700' : 'text-gray-500 hover:text-gray-800'}`}>
                            <a href="#" className="flex" onClick={(e) => setActiveTab(2)}>Budget</a>
                        </li>

                        <li className={`flex pr-4 mx-2 py-2 ${activeTab === 3 ? 'text-blue-700 border-b-2 border-blue-700' : 'text-gray-500 hover:text-gray-800'}`}>
                            <a href="#" className="flex" onClick={(e) => setActiveTab(3) }>Purchase & Expense</a>
                        </li>
                
                        <li className={`flex pr-4 mx-2 py-2 ${activeTab === 4 ? 'text-blue-700 border-b-2 border-blue-700' : 'text-gray-500 hover:text-gray-800'}`}>
                            <a href="#" className="flex" onClick={(e) => setActiveTab(4)}>Production</a>
                        </li>

                        <li className={`flex pr-4 mx-2 py-2 ${activeTab === 5 ? 'text-blue-700 border-b-2 border-blue-700' : 'text-gray-500 hover:text-gray-800'}`}>
                            <a href="#" className="flex" onClick={(e) => setActiveTab(5) }>Closure</a>
                        </li>
                    </ul>
                </div>

                <div className="mr-2 pt-3">
                    {activeTab === 1 && 
                        <Project 
                            data={data} 
                            setData={setData}
                            setNotification={setNotification}
                            setMessage={setMessage}
                        />}

                    {activeTab === 2 && 
                        <Budget 
                            data={data}
                            setData={setData}
                            setNotification={setNotification}
                            setMessage={setMessage}
                        />}

                    {activeTab === 3 && 
                        <PurchaseExpense
                            data={data}
                            setData={setData}
                            setNotification={setNotification}
                            setMessage={setMessage}
                        />}
                    
                    {activeTab === 4 && 
                        <Production
                            data={data}
                            setData={setData}
                            setNotification={setNotification}
                            setMessage={setMessage}
                        />}
                    {activeTab === 5 && 
                        <Closure 
                            data={data} 
                            setData={setData} 
                            setNotification={setNotification}
                            setMessage={setMessage}
                        />}
                </div>
            </div>

        </div>
    )
}

export default OrderEdit;