import { useRef } from "react";
import useOrder from "../hooks/useOrder";
import useAuth from "../hooks/useAuth";

import { ExternalLink } from 'lucide-react';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Closure = ({data, setData, setNotification, setMessage}) => {


    const {auth} = useAuth();
    const isEditable = () => {
        if(auth.groups.includes('viewer') || data.clouser_approval_status === 'approved' || data.production_approval_status !== 'approved')
            return false;        
        return true;
    }

    const patchOrder = useOrder();

    let signed_dc_wcc_copy = useRef(null)

    let payment_due_date = useRef(data.payment_due_date)
    let balance_payable = useRef(data.balance_payable)
    let tds_deducted = useRef(data.tds_deducted)
    let total_payable = useRef(data.total_payable)
    let other_deductions = useRef(data.other_deductions)
    let total_paid = useRef(data.total_paid)
    let rating = useRef(data.rating)
    let customer_feedback = useRef(data.customer_feedback)
    let vendor_rating = useRef(data.vendor_rating)
    let challenges = useRef(data.challenges)
    let solutions_and_case_study = useRef(data.solutions_and_case_study)

    const updateInfo = async () => {

        setMessage("Updating...");
        setNotification(true);

        let formData = new FormData();

        formData.append("payment_due_date", payment_due_date.current.value)
        formData.append("balance_payable", balance_payable.current.value)
        formData.append("tds_deducted", tds_deducted.current.value)
        formData.append("total_payable", total_payable.current.value)
        formData.append("other_deductions", other_deductions.current.value)
        formData.append("total_paid", total_paid.current.value)
        formData.append("rating", rating.current.value)
        formData.append("customer_feedback", customer_feedback.current.value)
        formData.append("vendor_rating", vendor_rating.current.value)
        formData.append("challenges", challenges.current.value)
        formData.append("solutions_and_case_study", solutions_and_case_study.current.valu)

        if(signed_dc_wcc_copy.current && signed_dc_wcc_copy.current.files[0]){
            const file = signed_dc_wcc_copy.current.files[0];
            const file_format = file?.name.split('.').pop();
            const file_name = "signed_dc_wcc_copy" + "." + file_format;
            formData.append("signed_dc_wcc_copy", file, file_name);
        }
    
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        const response = await patchOrder(data.id, formData, config);
        console.log("Clouser.js::updateInfo", response.status);
        if(response.status === 200){
            setData(response.data);
            setMessage("Saved!");
            setNotification(true);
        } else {
            setMessage("Failed to save, please check the console for errors");
            setNotification(true);
        }
    }

    return (
        <div className={`${ !isEditable() ? 'text-gray-500' : ''}`}>
            <div>
                
                <div className="flex m-2 justify-between">
                    <h3>Signed DC/WCC Copy</h3>
                    <div className="flex">
                        <input
                            type="file"
                            disabled={!isEditable()}
                            ref={signed_dc_wcc_copy}
                        />
                        {data.signed_dc_wcc_copy &&
                            <div className="mx-2 flex">
                                <a href={data.signed_dc_wcc_copy} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                            </div>
                        }
                    </div>
                </div>

            </div>

            <div className="flex m-2">
                <label className="w-1/3">Payment Due Date</label>
                <input
                    className="border w-full px-2"
                    type="date" 
                    disabled={!isEditable()}
                    ref={payment_due_date}
                    defaultValue={data.payment_due_date}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Balance Payable</label>
                <input
                    className="border w-full px-2"
                    type="text" 
                    disabled={!isEditable()}
                    ref={balance_payable}
                    defaultValue={data.balance_payable}/>
            </div>
            <div className="flex m-2">
                <label className="w-1/3">TDS Deducted</label>
                <input
                    className="border w-full px-2"
                    type="text"
                    disabled={!isEditable()}
                    ref={tds_deducted}
                    defaultValue={data.tds_deducted}/>
            </div>
            <div className="flex m-2">
                <label className="w-1/3">Total Payable</label>
                <input
                    className="border w-full px-2"
                    type="text"
                    disabled={!isEditable()}
                    ref={total_payable}
                    defaultValue={data.total_payable}/>
            </div>
            <div className="flex m-2">
                <label className="w-1/3">Other Deductions</label>
                <input
                    className="border w-full px-2"
                    type="text" 
                    disabled={!isEditable()}
                    ref={other_deductions}
                    defaultValue={data.other_deductions}/>
            </div>
            
            <div className="flex m-2">
                <label className="w-1/3">Total Paid</label>
                <input
                    className="border w-full px-2"
                    type="text"
                    disabled={!isEditable()}
                    ref={total_paid}
                    defaultValue={data.total_paid}
                    />
            </div>
            {/* (Trigger a thank you mail) */}
            
            <div className="flex m-2">
                <label className="w-1/3">Review/Ratings (out of 10)</label>
                <input
                    className="border w-full px-2"
                    type="text"
                    disabled={!isEditable()}
                    ref={rating}
                    defaultValue={data.rating}/>
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Customer Feedback</label>
                <textarea
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={customer_feedback}
                    defaultValue={data.customer_feedback}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Vendor Ratings (out of 10)</label>
                <input
                    type="text"
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={vendor_rating}
                    defaultValue={data.vendor_rating}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Challenges</label>
                <textarea
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={challenges}
                    defaultValue={data.challenges}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Solutions & Case study</label>
                <textarea
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={solutions_and_case_study}
                    defaultValue={data.solutions_and_case_study}/>
            </div>

            {isEditable() &&
                <div className="my-5">
                    <button 
                        className="border w-full py-1 transition duration-200 bg-black hover:bg-blue-600 text-white rounded text-sm capitalize"
                        onClick={updateInfo}>
                            save
                    </button>
                </div>
            }
        
        </div>
    )
}

export default Closure;