import { Outlet } from "react-router-dom";

import { CirclePlus, LayoutDashboard } from "lucide-react";

import Sidebar, { SidebarItem } from "./Sidebar";
import useAuth from "../hooks/useAuth";

const Layout = () => {
    const {auth} = useAuth();
    
    return (
        <main className="App">
            <div className="flex">
                
                <Sidebar user={auth.name} email={auth.email} >
                    <SidebarItem icon={<LayoutDashboard size={20} />}  text="Jobcards" page='Jobcards'/>
                    <SidebarItem icon={<CirclePlus size={20} />}  text="Create new" page='jobcard/new/'/>
                </Sidebar>
            
                <div className="w-full overflow-y-auto h-screen">
                    <Outlet />
                </div>
            </div>
        </main>
    )
}
export default Layout;