import axios from '../api/axios';
import useAuth from './useAuth';

import * as jose from 'jose';

const REFRESH_URL = '/token/refresh/';

const useRefreshToken = () => {

    const { setAuth } = useAuth();

    const refresh = async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        const refresh = token.refresh;

        const response = await axios.post(
            REFRESH_URL,
            {'refresh': String(refresh)},
            {withCredentials: true}
        );

        const accessToken = response?.data?.access;
        const refreshToken = response?.data?.refresh;
        const {user_id, name, email, groups} = jose.decodeJwt(accessToken);

        setAuth({ user_id, name, email, groups, accessToken });
        localStorage.setItem('token', JSON.stringify({'access': accessToken, 'refresh': refreshToken}));
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;