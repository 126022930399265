import { useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const CreateCashBased = ({order_id, setNotification, setMessage, closeModal}) => {

    const axios = useAxiosPrivate();

    let vendor = useRef('')
    let primary_poc = useRef('')
    let primary_contact_no = useRef('')
    let primary_email = useRef('')
    let product_renderd = useRef('')
    let invoice_date = useRef('')
    let invoice_no = useRef('')
    let total_invoice_amount = useRef('')
    let tax = useRef('')

    let invoice = useRef(null)

    const createCash = async () => {

        setMessage('Creating...');
        setNotification(true);

        let formData = new FormData();

        formData.append("jobcard_id", order_id)
        formData.append("vendor", vendor.current.value)
        formData.append("primary_poc", primary_poc.current.value)
        formData.append("primary_contact_no", primary_contact_no.current.value)
        formData.append("primary_email", primary_email.current.value)
        formData.append("product_renderd", product_renderd.current.value)
        formData.append("invoice_date", invoice_date.current.value)
        formData.append("invoice_no", invoice_no.current.value)
        formData.append("total_invoice_amount", total_invoice_amount.current.value)
        formData.append("tax", tax.current.value)

        const file = invoice.current.files[0]

        formData.append(invoice.current.name, file);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            }
        };

        try {
            const response = await axios.post('/cash/', formData, config);
            if(response.status === 201){
                setMessage('Done');
                setNotification(true);
                closeModal();
            }
        } catch (error) {
            console.log(error)
            setMessage('Failed to create, Please check the console for errors');
            setNotification(true);
        }
    }

    return (
        <div>

            <div className="text-right">
                <button onClick={() => closeModal()}>
                    X
                </button>
            </div>

            <h3 className="text-center my-2">Add new Cash based order</h3>
            <br/>

            <div className="flex m-2">
                <label className="w-1/2">Vendor/Supplier</label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    ref={vendor}
                    />
            </div>
            
            <div className="flex m-2">
                <label className="w-1/2">Contact Person Name</label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    ref={primary_poc}
                    />
            </div>
            
            <div className="flex m-2">
                <label className="w-1/2">Contact No</label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    ref={primary_contact_no}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/2">Email</label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    ref={primary_email}
                    />
            </div>
            
            <div className="flex m-2">
                <label className="w-1/2">Product/Service rendered</label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    ref={product_renderd}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/2">Invoice Date</label>
                <input 
                    type="date" 
                    className="border w-full px-2"
                    ref={invoice_date}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/2">Invoice No</label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    ref={invoice_no}
                    />
            </div>

            <div className="flex m-2 justify-between">
                <h3>upload Invoice</h3>
                <input 
                    type="file" 
                    name="invoice"
                    ref={invoice}
                    />
            </div>
            

            <div className="flex m-2">
                <label className="w-1/2">Invoice Amount</label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    ref={total_invoice_amount}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/2">Tax</label>
                <input 
                    type="text" 
                    className="border w-full px-2"
                    ref={tax}
                    />
            </div>

            <div className="flex m-2">
                <button
                    className="border w-full py-2 my-2 transition duration-200 bg-black hover:bg-blue-600 text-white rounded text-sm capitalize"
                    onClick={createCash}>
                    Create Cash Based Order
                </button>
            </div>
            
        </div>
    )
}
export default CreateCashBased;