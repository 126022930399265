import {useRef, useState, useEffect } from "react";

import useAuth from "../hooks/useAuth";
import useOrder from "../hooks/useOrder";

import {ExternalLink, SquarePlus} from 'lucide-react';

const Project = ({data, setData, setNotification, setMessage}) => {
    
    const {auth} = useAuth();
    const isEditable = () => {
        if(auth.groups.includes('viewer') || data.project_approval_status === 'approved')
            return false;        
        return true;
    }

    const patchOrder = useOrder();

    let jobcard_name_ref = useRef(data.jobcard_name)
    let company_name_ref = useRef(data.company_name);
    let concern_department_ref = useRef(data.concern_department);
    let concern_person_ref = useRef(data.concern_person);
    let contact_no_ref = useRef(data.contact_no);
    let email_ref = useRef(data.email);

    let estimate_no_ref = useRef(data.estimate_no);
    let selling_value_ref = useRef(data.selling_value);

    const [designRequired, setDesignRequired] = useState(false);
    let design_required_ref = useRef(data.design_required_ref);
    let design_charges_ref = useRef(data.design_charges);

    const [sampleRequired, setSampleRequired] = useState(false);
    let sample_required_ref = useRef(data.sample_charges);
    let sample_charges_ref = useRef(data.sample_charges_ref);

    let transportation_charges_ref = useRef(data.transportation_charges);
    let other_charges_ref = useRef(data.other_charges);
    let gst_ref = useRef(data.gst);

    // const [selling_value, setSellingValue] = useState(data.selling_value);
    // const [design_charges, setDesignCharges] = useState(data.design_charges);
    // const [sample_charges, setSampleCharges] = useState(data.sample_charges);
    // const [transportation_charges, setTransportationCharges] = useState(data.transportation_charges);
    // const [other_charges, setOtherCharges] = useState(data.other_charges);
    // const [gst, setGst] = useState(data.gst);

    const [total_amount, setTotalAmount] = useState();

    const [orderConfirmationMethod, setOrderConfirmationMethod] = useState('advance');

    let advance_amount_paid_ref = useRef(data.advance_amount_paid)
    let advance_method_ref = useRef(data.advance_amount_paid_method)
    let transaction_screenshot = useRef(null)
    
    let order_no_ref = useRef(data.po_order_no)
    let po_image = useRef(null)

    let email_confirmed_by_ref = useRef(null)
    let email_confirmation_image = useRef(null)
    let email_approved_by_ref = useRef(null)

    let order_confirmation_method_ref = useRef(null);

    const updateTotal = () => {
        let total = Number(selling_value_ref.current.value);

        total += Number(transportation_charges_ref.current.value);
        total += Number(other_charges_ref.current.value);
        total += Number(gst_ref.current.value);

        if(design_required_ref.current.checked){
            total += Number(design_charges_ref.current.value);
        }

        if(sample_required_ref.current.checked){
            total += Number(sample_charges_ref.current.value);
        }

        setTotalAmount(total);
    }
    
    useEffect(() => {

        // total
        updateTotal();

        // order confirmation method
        if(data.order_confirmation_method){
            setOrderConfirmationMethod(data.order_confirmation_method);
            return;
        } 
        setOrderConfirmationMethod('advance_payment');
            
    }, [data])


    const updateInfo = async () => {

        setMessage("Updating...");
        setNotification(true);

        let formData = new FormData();

        // meta
        formData.append("primary_executive", data.primary_executive);
        formData.append("secondary_executive", data.secondary_executive);
        formData.append("other_executives", data.other_executives);
        formData.append("company_name", company_name_ref.current.value);
        formData.append("concern_department", concern_department_ref.current.value);
        formData.append("concern_person", concern_person_ref.current.value);
        formData.append("contact_no", contact_no_ref.current.value);

        formData.append("customer_email", email_ref.current.value);
        formData.append("estimate_no", estimate_no_ref.current.value);

        formData.append("selling_value", selling_value_ref.current.value);

        formData.append("design_required", design_required_ref.current.checked);
        formData.append("design_charges", design_charges_ref.current.value);

        formData.append("sample_required", sample_required_ref.current.checked);
        formData.append("sample_charges", sample_charges_ref.current.value);

        formData.append("transportation_charges", transportation_charges_ref.current.value);
        formData.append("other_charges", other_charges_ref.current.value);
        formData.append("gst", gst_ref.current.value);

        console.log(order_confirmation_method_ref.current.value);
        formData.append("order_confirmation_method", order_confirmation_method_ref.current.value);


        if(orderConfirmationMethod === 'advance'){
            console.log('order confirmation method: advance')
            if(advance_amount_paid_ref.current)
                formData.append("advance_amount_paid", advance_amount_paid_ref.current.value);
    
            if(advance_method_ref.current)
                formData.append("advance_amount_paid_method", advance_method_ref.current.value);

            if(transaction_screenshot.current && transaction_screenshot.current.files[0]){
                const file = transaction_screenshot.current.files[0];

                console.log("file:", file);
                if(!file){
                    console.log('file is empty...')
                }

                const file_format = file?.name.split('.').pop();
                const file_name = "order_confirmation" + "." + file_format;
                formData.append("order_confirmation_image", file, file_name);
            }

            // reset PO and email
            formData.append("po_order_no", '');
            formData.append("email_confirmed_by", '');
            formData.append("email_approved_by", '');
        }

        if(orderConfirmationMethod === 'po'){
            console.log('order confirmation method: po')

            if(order_no_ref.current)
                formData.append("po_order_no", order_no_ref.current.value);

            if(po_image.current && po_image.current.files[0]){
                const file = po_image.current.files[0];

                const file_format = file?.name.split('.').pop();
                const file_name = "order_confirmation" + "." + file_format;
                formData.append("order_confirmation_image", file, file_name);
            }

            // reset advance and email
            formData.append("advance_amount_paid", '');
            formData.append("advance_amount_paid_method", '');
            formData.append("email_confirmed_by", '');
            formData.append("email_approved_by", '');
        }

        if(orderConfirmationMethod === 'email'){
            if(email_confirmed_by_ref.current)
                formData.append("email_confirmed_by", email_confirmed_by_ref.current.value);

            if(email_approved_by_ref.current)
                formData.append("email_approved_by", email_approved_by_ref.current.value);

            if(email_confirmation_image.current && email_confirmation_image.current.files[0]){
                const file = email_confirmation_image.current.files[0];

                const file_format = file.name.split('.').pop();
                const file_name = "order_confirmation" + "." + file_format;
                formData.append("order_confirmation_image", file, file_name);
            }

            // reset advance and po
            formData.append("advance_amount_paid", '');
            formData.append("advance_amount_paid_method", '');
            formData.append("po_order_no", '');
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
    
        const response = await patchOrder(data.id, formData, config);
        if(response.status === 200){
            setMessage("Saved!");
            setNotification(true);
            setData(response.data);
        } else {
            setMessage("Failed to update, please check the console for more details");
            setNotification(true);
            console.log(response)
        }

    }

    const handleUpload = (ref) => {
        // console.log(ref);
        ref.current.click();
    }


    return (

        <div className={`${ !isEditable() ? 'text-gray-500' : ''}`}>

            <div className="flex m-2">
                <label className="w-1/3">Jobcard Name</label>
                <input
                    className={`w-full ${data.jobcard_name ? '': 'border px-2'}`}
                    disabled={!isEditable()}
                    type="text"
                    ref={jobcard_name_ref}
                    defaultValue={data.jobcard_name}/>
            </div>
            
            <div className="flex m-2">
                <label className="w-1/3">Company Name</label>
                <input
                    className={`w-full ${data.company_name ? '': 'border px-2'}`}
                    disabled={!isEditable()}
                    type="text"
                    ref={company_name_ref}
                    defaultValue={data.company_name}/>
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Concern Department</label>
                <input
                    className={`w-full ${data.concern_department ? '': 'border px-2'}`}
                    disabled={!isEditable()}
                    type="text"
                    ref={concern_department_ref}
                    defaultValue={data.concern_department}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Concern Person</label>
                <input
                    className={`w-full ${data.concern_person ? '': 'border px-2'}`}
                    disabled={!isEditable()}
                    type="text"
                    ref={concern_person_ref}
                    defaultValue={data.concern_person}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Contact No</label>
                <input
                    className={`w-full ${data.contact_no ? '': 'border px-2'}`}
                    disabled={!isEditable()}
                    type="text"
                    ref={contact_no_ref}
                    defaultValue={data.contact_no}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Email</label>

                <input
                    className={`w-full ${data.customer_email ? '': 'border px-2'}`}
                    disabled={!isEditable()}
                    type="text"
                    ref={email_ref}
                    defaultValue={data.customer_email}
                    />
            </div>

            <hr/>

            <div className="flex m-2">
                <label className="w-1/3">Estimate No</label>
                <input
                    className={`w-full ${data.estimate_no ? '': 'border px-2'}`}
                    disabled={!isEditable()}
                    type="text"
                    ref={estimate_no_ref}
                    defaultValue={data.estimate_no}/>
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Selling Value</label>
                <input
                    className={`w-full ${data.selling_value ? '': 'border px-2'}`}
                    disabled={!isEditable()}
                    type="text"
                    defaultValue={data.selling_value}
                    ref={selling_value_ref}
                    onChange={updateTotal}
                    />
            </div>

            {/* design */}
            <div className="flex m-2">
                <div className="w-1/3 flex justify-between">
                    <label>Design Required</label>
                    <input 
                        className="mx-2"
                        type="checkbox" 
                        ref={design_required_ref}
                        defaultChecked={data.design_required}
                        disabled={!isEditable()}
                        onChange={(e) => {
                            setDesignRequired(e.target.checked);
                            updateTotal();
                        }}/>
                </div>

                <input
                    className={`border w-full px-2 ${design_required_ref?.current?.checked ? '': 'bg-gray-100'}`}
                    disabled={(!designRequired) || (!isEditable())}
                    type="text"
                    ref={design_charges_ref}
                    defaultValue={data.design_charges}
                    placeholder="design charges"
                    onChange={updateTotal}
                    />
            </div>

            {/* sample */}
            <div className="flex m-2">
                <div className="w-1/3 flex justify-between">
                    <label>Sample Required</label>
                    <input 
                        className="mx-2"
                        type="checkbox" 
                        ref={sample_required_ref}
                        defaultChecked={data.sample_required}
                        disabled={!isEditable()}
                        onChange={(e) => {
                            setSampleRequired(e.target.checked);
                            updateTotal();
                        }}
                        />
                </div>

                <input
                    className={`border w-full px-2 ${sample_required_ref?.current?.checked ? '': 'bg-gray-100'}`}
                    disabled={(!sampleRequired) || (!isEditable())}
                    type="text"
                    ref={sample_charges_ref}
                    defaultValue={data.sample_charges}
                    placeholder="sample charges"
                    onChange={updateTotal}
                    />
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Transport Charges</label>
                <input
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    type="text"
                    ref={transportation_charges_ref}
                    defaultValue={data.transportation_charges}
                    onChange={updateTotal}/>
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Other Charges</label>
                <input
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    ref={other_charges_ref}
                    type="text"
                    onChange={updateTotal}
                    defaultValue={data.other_charges}/>
            </div>

            <div className="flex m-2">
                <label className="w-1/3">GST</label>
                <input
                    className="border w-full px-2"
                    disabled={!isEditable()}
                    type="text"
                    ref={gst_ref}
                    onChange={updateTotal}
                    defaultValue={data.gst}/>
            </div>

            <div className="flex m-2">
                <label className="w-1/3">Total Amount</label>
                <input
                    className="border w-full px-2 bg-gray-100"
                    disabled={true}
                    type="text"
                    value={total_amount}
                    />
            </div>

            <hr/>

            <div className="flex m-2">
                <label className="w-1/3 capitalize pt-2 ">Order Confirmation Method</label>

                <select 
                    className="w-full px-2"
                    ref={order_confirmation_method_ref}
                    disabled={!isEditable()}
                    onChange={()=> setOrderConfirmationMethod(order_confirmation_method_ref.current.value)}>

                    <option value="advance_payment" selected={`${data.order_confirmation_method === 'advance_payment'? 'selected': ''}`}>Advance Payment</option>
                    <option value="po_without_advance" selected={`${data.order_confirmation_method === 'po_without_advance'? 'selected': ''}`}>PO With Advance</option>
                    <option value="po" selected={`${data.order_confirmation_method === 'po'? 'selected': ''}`}>PO Without Advance</option>
                    <option value="email" selected={`${data.order_confirmation_method === 'email'? 'selected': ''}`}>Email Confirmation</option>


                </select>

            </div>

            {orderConfirmationMethod === 'advance_payment' &&
                <div>
                    <div className="flex m-2">
                        <label className="w-1/3">Advance Amount Paid</label>
                        <input
                            className="border w-full px-2"
                            disabled={!isEditable()}
                            type="text"
                            ref={advance_amount_paid_ref}
                            defaultValue={data.advance_amount_paid}
                            />
                    </div>

                    <div className="flex m-2">
                        <label className="w-1/3">Payment Method</label>
                        <input
                            className="border w-full px-2"
                            disabled={!isEditable()}
                            type="text"
                            ref={advance_method_ref}
                            defaultValue={data.advance_amount_paid_method}/>
                    </div>

                    <div className="flex m-2 justify-between">
                        <label className="w-1/3">Upload Transaction Screenshot</label>
                        <button
                            className="w-full text-left"
                            onClick={() => handleUpload(transaction_screenshot)}>
                            <SquarePlus 
                                size={20}
                                className="transition duration-200 text-black hover:text-gray-600"
                                />
                        </button>
                        <input 
                            type="file" 
                            className="invisible w-0"
                            ref={transaction_screenshot}
                            name='transaction_screenshot'
                            disabled={!isEditable()}
                        />

                        {(data.order_confirmation_method === 'advance' && data.order_confirmation_image) &&
                            <div className="mx-2 flex">
                                <a href={data.order_confirmation_image} className="flex" target="_blank">
                                    <span >view</span>
                                    <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                            </div>
                        }
                    
                    </div>
                </div>    
            }

            {orderConfirmationMethod === 'po_without_advance' &&
                <div>
                    <div className="flex m-2">
                        <label className="w-1/3">Advance Amount Paid</label>
                        <input
                            className="border w-full px-2"
                            disabled={!isEditable()}
                            type="text"
                            ref={advance_amount_paid_ref}
                            defaultValue={data.advance_amount_paid}
                            />
                    </div>

                    <div className="flex m-2">
                        <label className="w-1/3">Payment Method</label>
                        <input
                            className="border w-full px-2"
                            disabled={!isEditable()}
                            type="text"
                            ref={advance_method_ref}
                            defaultValue={data.advance_amount_paid_method}/>
                    </div>
                    
                    <div className="flex m-2">
                        <label className="w-1/3">Order No</label>
                        <input
                            className="border w-full"
                            disabled={!isEditable()}
                            type="text"
                            ref={order_no_ref}
                            defaultValue={data.po_order_no}/>
                    </div>

                    <div>
                        <span className="m-2">
                            Upload PO Image
                        </span>
                    
                        <div className="flex m-2 justify-between">
                            <input
                                disabled={!isEditable()}
                                type="file"
                                ref={po_image} name='po_image'
                                />
                            {(data.order_confirmation_method === 'po' && data.order_confirmation_image) &&
                                <div className="mx-2 flex">
                                    <a href={data.order_confirmation_image} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {orderConfirmationMethod === 'po' &&
                <div>
                    <div className="flex m-2">
                        <label className="w-1/3">Order No</label>
                        <input
                            className="border w-full"
                            disabled={!isEditable()}
                            type="text"
                            ref={order_no_ref}
                            defaultValue={data.po_order_no}/>
                    </div>

                    <div>
                        <span className="m-2">
                            Upload PO Image
                        </span>
                    
                        <div className="flex m-2 justify-between">
                            <input
                                disabled={!isEditable()}
                                type="file"
                                ref={po_image} name='po_image'
                                />
                            {(data.order_confirmation_method === 'po' && data.order_confirmation_image) &&
                                <div className="mx-2 flex">
                                    <a href={data.order_confirmation_image} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {orderConfirmationMethod === 'email' &&
                <div>
                    <div className="flex m-2">
                        
                        <label className="w-1/3">Confirmed By</label>
                        <input
                            className="border w-full px-2"
                            disabled={!isEditable()}
                            type="text"
                            ref={email_confirmed_by_ref}
                            defaultValue={data.email_confirmed_by}/>
                    </div>

                    <div className="flex m-2">
                        <label className="w-1/3">Approved By</label>
                        <input
                            className="border w-full px-2"
                            disabled={!isEditable()}
                            type="text"
                            ref={email_approved_by_ref}
                            defaultValue={data.email_approved_by}/>
                    </div>

                    <div>
                        <span className="m-2">
                            Order Confirmation Screenshot
                        </span>
                        <div className="flex m-2 justify-between">
                            <input 
                                disabled={!isEditable()}
                                type="file"
                                ref={email_confirmation_image} 
                                name='email_confirmation_image'
                                />
                            {(data.order_confirmation_method === 'email' && data.order_confirmation_image) &&
                                <div className="mx-2 flex">
                                    <a href={data.order_confirmation_image} className="flex" target="_blank">view <ExternalLink size={15} className="my-auto mx-2 w-full"/></a>
                                </div>
                            }
                        </div>
                    </div>
                </div>}

            <hr/>

            { isEditable() && 
                <div className="my-5">
                    <button 
                        className="border w-full py-1 transition duration-200 bg-black hover:bg-blue-600 text-white rounded text-sm capitalize"
                        onClick={updateInfo}>
                            save
                    </button>
                </div>
            }

        </div>
    )
}

export default Project;