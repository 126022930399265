import useAxiosPrivate from "./useAxiosPrivate";
import useAuth from "./useAuth";

const useLogout = () => {
    const { setAuth } = useAuth();
    const axios = useAxiosPrivate();

    const logout = async () => {
        setAuth({});
        localStorage.removeItem('persist');
        localStorage.removeItem('token');
        try {
            const response = await axios('/logout/', {
                withCredentials: true
            });
            console.log(response);
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout