import { createContext, useContext, useState } from "react"
import { useNavigate, Link } from "react-router-dom";

import useLogout from "../hooks/useLogout";

import { ChevronsRight, ChevronsLeft, CircleUserRound, LogOut } from "lucide-react";
import logo from "../assets/images/logo.svg";

const SidebarContext = createContext();

export default function Sidebar({children, user, email}) {

    const [expanded, setExpanded] = useState(localStorage.getItem('sidebar-expanded') || false);

    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
      await logout();
      navigate('/');
    }

    const saveExpandedState = () => {
        localStorage.setItem('sidebar-expanded', !expanded)
        setExpanded((curr) => !curr);
    }

    return (
      <div>
        <aside className="h-screen">

          <nav className="h-full flex flex-col shadow-sm">

            <div className="p-4 flex justify-between items-center">

                <img src={logo}
                className={`overflow-hidden transistion-all ${expanded ? "w-40": "w-0"}`} 
                alt="instawings"/>

                <button 
                    onClick={saveExpandedState}
                    className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100">
                    {expanded ?<ChevronsLeft />: <ChevronsRight/>}
                </button>

            </div>
            
            <SidebarContext.Provider value={{expanded}}>
                <ul className="flex-1 px-3">{children}</ul>
            </SidebarContext.Provider>

            <button onClick={signOut}>
              <div className={`relative flex items-center py-2 px-3 mx-2 my-1 font-medium rounded-md cursor-pointer transition-colors group hover:bg-indigo-50 text-gray-600"}`}>
                <LogOut />
                <span className={`overflow-hidden transition-all text-left ${expanded ? "w-52 ml-3" : "w-0"}`}>
                  Logout
                </span> 

                {!expanded && (
                  <div className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 z-50`}>
                      Logout
                  </div>
                )}
              </div>
            </button>

            <div className="border-t flex p-3 justify-between items-center">
                <CircleUserRound className="w-8 h-10 rounded-md pl-2"/>
                <div 
                    className={`flex justify-between items-center overflow-hidden transition-all
                        ${expanded ? "w-52 ml-3": "w-0"}
                    `}>
                    
                    <div className="leading-4">
                        <h4 className="font-semibold">{user}</h4>
                        <span className="text-xs text-gray-600">{email}</span>
                    </div>
                </div>
            </div>
          </nav>
        </aside>
      </div>
    )
};

export function SidebarItem({ icon, text, page, active, alert }) {

    const { expanded } = useContext(SidebarContext)
    
    return (
      <Link to={page}>
        <li className={`capitalize relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group ${active ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800" : "hover:bg-indigo-50 text-gray-600"}`}>
            {icon}
            <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>{text}</span>
            {alert && (
                <div className={`z-10 absolute right-2 w-2 h-2 rounded bg-indigo-400 ${expanded ? "" : "top-2"}`}></div>
            )}

            {!expanded && (
                <div className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 z-50`}>
                    {text}
                </div>
            )} 
        </li>
      </Link>
    )
}