import DataTable from 'react-data-table-component';

import PopUp from '../components/PopUp';
import CreateCashBased from './CreateCashBased';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useState, useEffect } from "react";

import {ExternalLink} from 'lucide-react';

const CashBased = ({order_id, isEditable, setNotification, setMessage}) => {

    const [cashModal, setCashModal] = useState(false);
    const [data, setData] = useState([]);

    const axios = useAxiosPrivate()

    const columns = [
        {
            name: 'created',
            selector: row => row.created_date
        },
        {
            name: 'vendor',
            selector: row => row.vendor
        },
        {
            name: 'primary contact',
            selector: row => row.primary_poc
        },
        {
            name: 'contact No',
            selector: row => row.primary_contact_no
        },
        {
            name: 'Email',
            selector: row => row.primary_email
        },
        {
            name: 'invoice',
            selector: row => <a href={row.invoice} target="_blank" className='flex px-2'> <span className='px-2'>view</span> <ExternalLink  size={15}/></a>
        }
    ]

    const fetchData = async () => {
        try {
            const response = await axios.get(`/cash/?jobcard_id=${order_id}`);
            setData(response.data);
            
        } catch (error) {
            console.log('failed to get PO Data');
            console.log(error)
        }
    }

    useEffect(()=> {
        fetchData()
    }, [])


    return (
        <div className="border-b py-2">
            <PopUp isOpen={cashModal} onRequestClose={() => setCashModal(false)}>
                <CreateCashBased order_id={order_id} closeModal={() => setCashModal(false)} setNotification={setNotification} setMessage={setMessage}/>
            </PopUp>
            <div className='flex justify-between'>
                <h3>Cash Based</h3>
                {isEditable &&
                    <button 
                        className="px-2 transition duration-200 bg-black hover:bg-blue-600 text-white rounded"
                        onClick={() => setCashModal(true)}>
                            +
                    </button>
                }
            </div>

            <DataTable 
                className='flex-grow'
                columns={columns}
                data={data}
                highlightOnHover
            />
        </div>
    )

}

export default CashBased;