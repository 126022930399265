import { useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const CreateExpense = ({order_id, setNotification, setMessage, closeModal}) => {

    const axios = useAxiosPrivate();

    let ticket_no = useRef('');
    let ticket_date = useRef('');
    let expense_type = useRef('');
    let expense_details = useRef('');
    let expense_amount = useRef('');
    let reference_number = useRef('');
    let reference = useRef('')

    const CreateExp = async () => {

        setMessage('Creating...');
        setNotification(true);

        let formData = new FormData();
        formData.append("jobcard_id", order_id)
        formData.append("ticket_no", ticket_no.current.value)
        formData.append("ticket_date", ticket_date.current.value)
        formData.append("expense_type", expense_type.current.value)
        formData.append("expense_details", expense_details.current.value)
        formData.append("expense_amount", expense_amount.current.value)
        formData.append("reference_number", reference_number.current.value)

        const file = reference.current.files[0]
        console.log(reference.current.name)
        console.log(file)
        formData.append(reference.current.name, file)

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            }
        };

        try {
            const response = await axios.post('/expense/', formData, config);
            if(response.status === 201){
                setMessage('Done');
                setNotification(true);
                closeModal();
            }
        } catch (error) {
            console.log(error);
            setMessage('Failed to create, Please check the console for errors');
            setNotification(true);
        }

    }

    return (
        <div>
            <div className="text-right">
                <button onClick={() => closeModal()}>
                    X
                </button>
            </div>

                <h3 className="text-center">Add New Expense</h3>
                <br/>
            
            <div className="flex mx-2">
                <label className="w-1/2">Ticket No</label>
                <input 
                    type="text"
                    className="border w-full m-2"
                    ref={ticket_no}
                />
            </div>
            <div className="flex mx-2">
                <label className="w-1/2">Ticket Date</label>
                <input 
                    type="date"
                    className="border w-full m-2"
                    ref={ticket_date}
                />
            </div>
            <div className="flex mx-2">
                <label className="w-1/2">Expense Type</label>
                <input 
                    type="text" 
                    className="border w-full m-2"
                    ref={expense_type}
                />
            </div>
            <div className="flex mx-2">
                <label className="w-1/2">Expense Details</label>
                <input 
                    type="text"
                    className="border w-full m-2"
                    ref={expense_details}
                />
            </div>
            <div className="flex mx-2">
                <label className="w-1/2">Expense Amount</label>
                <input 
                    type="text" 
                    className="border w-full m-2"
                    ref={expense_amount}
                />
            </div>

            <div className="flex mx-2">
                <label className="w-1/2">Reference Number</label>
                <input 
                    type="text"
                    className="border w-full m-2"
                    ref={reference_number}
                />
            </div>

            <div className="flex m-2">
                <input
                    type="file"
                    ref={reference}
                    name='reference'
                />
                Reference image
            </div>

            <div>
                <button 
                    className="border w-full py-2 m-2 transition duration-200 bg-black hover:bg-blue-600 text-white rounded text-sm capitalize"
                    onClick={CreateExp}>
                    create Expense
                </button>
            </div>
            
        </div>
    )
}

export default CreateExpense;