import DataTable from 'react-data-table-component';
import PopUp from '../components/PopUp';
import { useState, useEffect } from "react";
import CreateExpense from './CreateExpense';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import {ExternalLink} from 'lucide-react';

const Expense = ({order_id, isEditable, setNotification, setMessage}) => {
    const [expenseModal, setExpenseModal] = useState(false);
    const [data, setData] = useState([]);

    const axios = useAxiosPrivate()

    const columns = [
        {
            name: 'Ticket No',
            selector: row => row.ticket_no 
        },
        {
            name: 'Ticket Date',
            selector: row => row.ticket_date 
        },
        {
            name: 'Expense Type',
            selector: row => row.expense_type 
        },
        {
            name: 'Expense Amount',
            selector: row => row.expense_amount 
        },
        {
            name: 'Reference Number',
            selector: row => row.reference_number
        },
        {
            name: 'Reference',
            selector: row => <a href={row.reference} target="_blank" className='flex px-2'> <span className='px-2'>view</span> <ExternalLink  size={15}/></a>
        }
    ]

    const fetchData = async () => {
        try {
            const response = await axios.get(`/expense/?jobcard_id=${order_id}`);
            setData(response.data)
        } catch (error) {
            console.log('failed to get Expense Data');
            console.log(error)
        }
    }
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className="border-b py-2">
        <PopUp isOpen={expenseModal} onRequestClose={() => setExpenseModal(false)}>
            <CreateExpense order_id={order_id} closeModal={() => setExpenseModal(false)} setNotification={setNotification} setMessage={setMessage}/>
        </PopUp>
        <div className='flex justify-between'>
            <h3>Expense</h3>
            {isEditable &&
                <button 
                    className="px-2 transition duration-200 bg-black hover:bg-blue-600 text-white rounded"
                    onClick={() => setExpenseModal(true)}>
                        +
                </button>
            }
        </div>

        <DataTable 
            columns={columns}
            data={data}
        />
    </div>   
    )
}

export default Expense;