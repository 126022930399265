import { createContext, useEffect, useState } from "react";
import * as jose from 'jose';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    
    const [auth, setAuth] = useState({});
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || false);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));

        if(token){
            const accessToken = token.access;
            const {user_id, name, groups, email} = jose.decodeJwt(token.access);
            setAuth({ user_id, name, email, groups, accessToken });
            setAuthenticated(true);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth, persist, setPersist, isAuthenticated}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;